import { important } from 'csx';
import { style } from 'typestyle';

export const checkboxesStyle = style({
  alignSelf: 'center',
  justifySelf: 'center',
  padding: '0.625rem 0',
  $nest: {
    '.flow-status-layout': {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: '16rem',
      marginRight: '0.5rem',
    },
    '.check-input': {
      marginRight: '0.25rem',
    },
    '.check-label': {
      fontSize: '0.75rem',
      margin: 0,
      $nest: {
        '&.selected': {
          color: 'var(--teal)',
        },
        '&:not(:last-of-type)': {
          paddingRight: '0.75rem',
        },
      },
    },
  },
});

export const menuItemStyles = style({
  color: important('black'),
  backgroundColor: important('white'),
});
