import { style } from 'typestyle';
import { primaryTextColor, mediumGutterWidth } from 'src/utils/Style/Theme';
import { NestedCSSProperties } from 'typestyle/lib/types';

const content = style({
  $debugName: 'content',
  height: `100%`,
  flexGrow: 1,
});

const contentContainer = style({
  $debugName: 'contentContainer',
  height: 'calc(100% - 70px)',
});

const sidenavClass = style({
  $debugName: 'sidenavClass',
  display: 'inline-block',
  $nest: {
    '.nav-link': {
      paddingLeft: '2rem',
    },
  },
});

const mainClassObj: NestedCSSProperties = {
  $debugName: 'mainClass',
  color: primaryTextColor,
  width: '100%',
  overflow: 'hidden',
  display: 'inline-block',
  position: 'relative',
};

const mainClassPrintable = style({
  ...mainClassObj,
  display: 'block',
  overflowX: 'visible',
  overflowY: 'visible',
  height: 'calc(100% - 28px)',
});

const mainClass = style({
  ...mainClassObj,
  height: 'calc(100% - 75px)',
  padding: `0px ${mediumGutterWidth}px`,
});

const viewPortPrintable = style({
  height: '100vh',
  display: 'flex',
  flexWrap: 'wrap',
  lineHeight: 0,
});

const belowHeaderBarsDiv = style({
  width: '100%',
  height: '100%',
  display: 'flex',
});

export const viewPortStyle = style({
  height: '100vh',
  overflow: 'hidden',
  $nest: {
    '&.print': {
      height: 'auto',
      display: 'inline-block',
    },
  },
});

export default {
  content,
  contentContainer,
  mainClass,
  sidenavClass,
  mainClassPrintable,
  viewPortPrintable,
  belowHeaderBarsDiv,
};
